<!-- 培训班级类别——授课老师 -->
<template>
    <div class="TeacherData">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">培训班级类别—授课老师</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 查询表单 -->
        <!-- <el-form ref="form" :model="form" inline class="form" label-width="80px">
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item label="选择学科">
                <el-select v-model="form.subject" placeholder="请选择学科" :clearable="true">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择">
                <el-select v-model="form.choice" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.choiceOption" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="" style="margin-left: 25px;margin-top: 5px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </el-form> -->
        <div class="" style="margin-left: 21px;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;" @click="newlyAdded">
                新增
            </el-button>
        </div>
        <!-- 授课老师 列表 -->
        <el-table :data="teacherList" border stripe style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="status" sortable label="负责状态" align="center" width="120">
                <template slot-scope="scope">
                    <span v-if="scope.row.status==0">不负责</span>
                    <span v-else-if="scope.row.status==1">在负责</span>
                </template>
            </el-table-column>
            <el-table-column prop="priority" sortable label="优先级" align="center" width="120">
            </el-table-column>
            <el-table-column prop="resources" sortable label="授课老师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="ratio" sortable label="协助比例(%)" align="center" width="130">
            </el-table-column>
            <el-table-column prop="class_category" sortable label="培训课" align="center" width="200">
            </el-table-column>
            <el-table-column prop="admin" sortable label="设置人" align="center" width="120">
            </el-table-column>
            <el-table-column prop="set_time" sortable label="设置日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="remark" sortable label="备注" align="center" width="300">
            </el-table-column>
            <el-table-column  label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;"
                     @click="editTeacher(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini"
                        @click="delt(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    search: "", //搜索
                    subject: "", //选择学科
                    subjectOpt:[],//学科选项
                    choice: "", //选择
                    choiceOption: [{
                            value: '0',
                            label: '全部'
                        },
                        {
                            value: '1',
                            label: '准备'
                        },
                        {
                            value: '2',
                            label: '启用'
                        },
                        {
                            value: '3',
                            label: '停用'
                        }
                    ] //选择选项
                },
                teacherList: [], //班级数据
                currentPage: 1,
                currentLimit: 20,//条数
                total: 0,
            }
        },
        created() {
            // 学科数据
            this.$request({
                url: '/api/course/list',
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log(res, "培训科目")
                this.form.subjectOpt = res.data.list
            })
            
            this.getList()
        },
        activated() {
            this.getList()
        },
        methods: {
            goBack() { //返回
                this.$router.go(-1);
            },
            getList(){
                this.$request({
                    url:'/api/teacher/list',
                    method:"POST",
                    data:{
                        class_category_id:this.$route.query.class_category_id,
                        page:this.currentPage,
                        limit:this.currentLimit,
                        sort:this.arr
                    }
                }).then(res=>{
                    if(res.code==1){
                        console.log(res)
                        this.teacherList=res.data.list
                        this.total=res.data.count
                    }
                })
            },
            handleSizeChange(val) {
            	this.currentLimit = val
            	this.getList()
            },
            handleCurrentChange(val) {
                this.currentPage = val
            	this.getList()
            },
            // 刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addTeacher",
                    query:{
                        class_category_id:this.$route.query.class_category_id
                    }
                })
            },
            editTeacher(index,row){
               this.$router.push({
                   path: "editTeacher",
                   query:{
                       id:row.id
                   }
               }) 
            },
            delt(index,row){//删除
                this.$confirm('是否删除此条记录?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url: '/api/teacher/del',
                        method: 'POST',
                        data: {
                            id: row.id
                        }
                    }).then(res => {
                        if (res.code == 1) {
                            this.$message({
                                message: res.msg,
                                type: 'success'
                            });
                            this.teacherList.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            query() { //查询
                this.currentPage = 1
                this.getList()
            },
            Reset() { //重置
                this.form.search = '' //搜索
                this.form.subject = '' //学科
                this.form.choice = ''//选择
                this.getList()
            },
            //排序
            abc(i){ 
                console.log(i)
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }
    }
</script>

<style scoped="scoped">
    .TeacherData {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 5px 28px;
        display: flex;
        justify-content: space-between;
    }

    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }

    /* 查询部分 */
    .form {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    /* 新增按钮 */
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
